import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  indexedDbStoresName,
  indexedDbSystemStoresName,
} from '@models/indexed-db-stores.model';
import { Nullable } from '@models/nullable.model';
import { SystemStateService } from '@state-management/system-state';
import {
  catchError,
  combineLatest,
  concat,
  filter,
  from,
  interval,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { DB_NAME } from './_core/core-indexed-db.constants';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private readonly isOffline = inject(SystemStateService).getValue('isOffline');
  private readonly isOffline$ = toObservable(this.isOffline);

  private readonly RETRIES = 3;

  private db: IDBDatabase | undefined = undefined;

  protected dbName = DB_NAME;
  protected storeParameters: IDBObjectStoreParameters = {
    keyPath: null,
  };

  protected currentVersion: number | undefined = undefined;

  readonly storesName: indexedDbStoresName[] = [
    'tasks',
    'markers',
    'notes',
    'samplings',
    'sampling-markers',
  ];
  readonly systemStoresName: indexedDbSystemStoresName[] = ['offline'];

  onCloseDB = signal({ success: true });
  canSyncData = toSignal(
    combineLatest([
      this.isOffline$,
      from(this.getDB()),
      interval(1000 * 30).pipe(startWith(0)),
    ]).pipe(
      shareReplay(1),
      filter(([, db]) => !!db),
      switchMap(([isOffline]) => {
        if (isOffline) return of(false);

        return from(
          Promise.all(
            this.storesName.map((storeName) => this.getAllRecords(storeName)),
          ),
        ).pipe(
          map((results) => results.some((records) => !!records.length)),
          catchError(() => from([false])),
        );
      }),
      catchError(() => from([false])),
    ),
    { initialValue: false },
  );

  async getDB(): Promise<IDBDatabase> {
    return this.db ?? (await this.resetDB());
  }

  deleteAllData(): Observable<Nullable<void>> {
    const obs$ = this.storesName.map((storeName) =>
      from(this.deleteAllRecords(storeName)),
    );
    return concat(...obs$).pipe(take(1));
  }

  async resetDB(): Promise<IDBDatabase> {
    const version = this.currentVersion ? this.currentVersion + 1 : undefined;
    this.db = await this.openDB(version);
    return this.db;
  }

  async retryOperationDB<T>(
    callback: (...args: unknown[]) => Promise<T>,
    counter = 0,
  ): Promise<T> {
    if (counter > this.RETRIES)
      return Promise.reject(new Error('DB_NO_CONNECTION'));

    try {
      return await callback();
    } catch {
      await this.resetDB();
      return this.retryOperationDB(callback, counter + 1);
    }
  }

  private async getAllRecords<T>(storeName: string): Promise<T[]> {
    try {
      const db = await this.getDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.getAll();

        request.onsuccess = (): void => {
          resolve(request.result as T[]);
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    } catch {
      return Promise.resolve([] as T[]);
    }
  }

  private async deleteAllRecords(storeName: string): Promise<void> {
    return this.retryOperationDB(async () => {
      const db = await this.getDB();

      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.clear();

        request.onsuccess = (): void => {
          resolve();
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }

  private openDB(version?: number): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      this.db?.close();
      const request = indexedDB.open(this.dbName, version);

      request.onupgradeneeded = (): void => {
        const db = request.result;

        [...this.storesName, ...this.systemStoresName].forEach((storeName) => {
          if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName, this.storeParameters);
          }
        });
      };

      request.onsuccess = (): void => {
        this.currentVersion = request.result.version;

        const existsAllObjectStores = [
          ...this.storesName,
          ...this.systemStoresName,
        ].every((storeName) =>
          request.result.objectStoreNames.contains(storeName),
        );
        if (!existsAllObjectStores) {
          request.result.close();
          this.db = undefined;
          resolve(this.openDB(this.currentVersion + 1));
        }

        request.result.onclose = (): void => {
          this.db = undefined;
          this.onCloseDB.set({ success: true });
        };
        resolve(request.result);
      };

      request.onerror = (): void => {
        console.error('IndexedDB error:', request.error);
        reject(request.error);
      };
    });
  }
}
